// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abonnieren-tsx": () => import("./../../../src/pages/abonnieren.tsx" /* webpackChunkName: "component---src-pages-abonnieren-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-anmelden-tsx": () => import("./../../../src/pages/anmelden.tsx" /* webpackChunkName: "component---src-pages-anmelden-tsx" */),
  "component---src-pages-autoren-tsx": () => import("./../../../src/pages/autoren.tsx" /* webpackChunkName: "component---src-pages-autoren-tsx" */),
  "component---src-pages-autorenvereinbarung-tsx": () => import("./../../../src/pages/autorenvereinbarung.tsx" /* webpackChunkName: "component---src-pages-autorenvereinbarung-tsx" */),
  "component---src-pages-codes-tsx": () => import("./../../../src/pages/codes.tsx" /* webpackChunkName: "component---src-pages-codes-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-freiexemplar-tsx": () => import("./../../../src/pages/freiexemplar.tsx" /* webpackChunkName: "component---src-pages-freiexemplar-tsx" */),
  "component---src-pages-gespraechskreise-tsx": () => import("./../../../src/pages/gespraechskreise.tsx" /* webpackChunkName: "component---src-pages-gespraechskreise-tsx" */),
  "component---src-pages-gfk-tsx": () => import("./../../../src/pages/gfk.tsx" /* webpackChunkName: "component---src-pages-gfk-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lesezeichen-tsx": () => import("./../../../src/pages/lesezeichen.tsx" /* webpackChunkName: "component---src-pages-lesezeichen-tsx" */),
  "component---src-pages-makronaut-tsx": () => import("./../../../src/pages/makronaut.tsx" /* webpackChunkName: "component---src-pages-makronaut-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-passwort-vergessen-tsx": () => import("./../../../src/pages/passwort-vergessen.tsx" /* webpackChunkName: "component---src-pages-passwort-vergessen-tsx" */),
  "component---src-pages-registrieren-tsx": () => import("./../../../src/pages/registrieren.tsx" /* webpackChunkName: "component---src-pages-registrieren-tsx" */),
  "component---src-pages-themenhefte-tsx": () => import("./../../../src/pages/themenhefte.tsx" /* webpackChunkName: "component---src-pages-themenhefte-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-pages-weihnachten-tsx": () => import("./../../../src/pages/weihnachten.tsx" /* webpackChunkName: "component---src-pages-weihnachten-tsx" */),
  "component---src-templates-all-articles-tsx": () => import("./../../../src/templates/all-articles.tsx" /* webpackChunkName: "component---src-templates-all-articles-tsx" */),
  "component---src-templates-all-editions-tsx": () => import("./../../../src/templates/all-editions.tsx" /* webpackChunkName: "component---src-templates-all-editions-tsx" */),
  "component---src-templates-all-spotlights-tsx": () => import("./../../../src/templates/all-spotlights.tsx" /* webpackChunkName: "component---src-templates-all-spotlights-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-magazine-tsx": () => import("./../../../src/templates/magazine.tsx" /* webpackChunkName: "component---src-templates-magazine-tsx" */),
  "component---src-templates-podcasts-tsx": () => import("./../../../src/templates/podcasts.tsx" /* webpackChunkName: "component---src-templates-podcasts-tsx" */)
}

