/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import React, { useEffect, useRef, useState } from "react"
import { AuthContextProvider } from "./src/services/firebase/auth-context/provider"
import Modal from "react-modal"
import { IPBasedLoginContextProvider } from "./src/services/ip-based-login/provider"
import Stackdriver from "./src/services/stackdriver/index"
import LoadingBar from "react-top-loading-bar"
import { globalHistory } from "@reach/router"
import tailwindConfig from "./tailwind.config"
import elementClosest from "element-closest"

Modal.setAppElement("body")

const getArticleColor = (path) => {
  const colorsMap = {
    "is-magazine": "makroPurple",
    "is-spotlight": "makroGreen",
    "is-edition": "makroOrange"
  }
  const type = path.startsWith("/themenhefte")
    ? "is-magazine"
    : path.startsWith("/spotlight")
    ? "is-spotlight"
    : "is-edition"

  return colorsMap[type]
}

const LoadingIndicator = () => {
  const ref = useRef(null)
  const [color, setColor] = useState("makroOrange")
  const colors = tailwindConfig.theme.extend.colors
  elementClosest(window)

  useEffect(() => {
    const triggerLoading = (e) => {
      const anchor = e.target.closest("a")
      if (anchor?.href?.includes(window.location.host)) {
        const color = getArticleColor(anchor.pathname)
        setColor(colors[color])
        setTimeout(ref.current.continuousStart(), 0)
      }
    }
    const docClickListener = document.addEventListener("click", triggerLoading, true)
    const historyListener = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setTimeout(ref.current.complete(), 200)
      }
    })
    return () => {
      docClickListener()
      historyListener()
    }
  }, []) // eslint-disable-line

  return <LoadingBar color={color} ref={ref} />
}

export const wrapRootElement = (props) => {
  const element = props.element

  return (
    <AuthContextProvider>
      <Stackdriver>
        <LoadingIndicator />
        <IPBasedLoginContextProvider>{element}</IPBasedLoginContextProvider>
      </Stackdriver>
    </AuthContextProvider>
  )
}

export const onClientEntry = async () => {
  const div = document.getElementById("top_loading_bar")
  const color = getArticleColor(window.location.pathname)
  setTimeout(() => div.classList.add("loading", `bg-${color}`), 2000)
}

export const onPostPrefetchPathname = () => {
  const div = document.getElementById("top_loading_bar")
  div.classList.add("completed")
  setTimeout(() => div.classList.add("hidden"), 1000)
}

// set Fathom analytics Goal code
export const onRouteUpdate = ({ location }) => {
  const queryParams = location.search.split("?")[1]?.split("&")
  const mtcParam = queryParams?.find((e) => e.includes("mtc="))
  const mtcVal = mtcParam?.split("=")[1]
  // TODO: restore, leads to `TypeError: navigator.sendBeacon is not a function`
  //  window.fathom.trackGoal(mtcVal, 0)
}
